import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

class BlogIndex extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const sessionKey  = window.sessionStorage.getItem('rapplerPlus');
    const email = window.sessionStorage.getItem("rapplerPlusEmail");
    const password = window.sessionStorage.getItem("rapplerPlusPassword");

    if(sessionKey)
      return;
    
    if(email)
      return;

    if(password === "r4ppl3rPlu$El3ction")
      return;

    window.location.href = "/";
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges
    let isReversed = false;
    
    //This is a quick fix only to avoid reversing of posts
    if(posts) {
      if(posts[0].node.fields.slug === '/methodology/') {
        console.log(posts[0]);
        posts.reverse();
        isReversed = true;
      }
    }
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="All posts"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <Bio />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <div key={node.fields.slug}>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                  color: '#e76229'
                }}
              >
                <Link style={{ boxShadow: `none`, color: '#252525' }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <small style={{color: '#959595'}}> {node.frontmatter.author} | {node.frontmatter.date}</small>
              <p style={{color: '#959595'}}
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
              <hr />
            </div>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            author
            description
          }
        }
      }
    }
  }
`
